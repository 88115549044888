<template>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >

    <b-row>
      <b-col
          cols="12"
          lg="12"
        >
        <order-data   :orderData="orderData"  :orderDataItemsNumber="orderDataItemsNumber"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col
          cols="12"
          lg="6"
        >
          <customer-info :userData="userData" />
        </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <provider :userData="provider" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <order-status :orderDataStatus="orderDataStatus" />
      </b-col>

      <b-col cols="12" lg="6">
        <order-products :orderProducts="orderProducts" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="12">
        <div v-if="!isDataLoaded">Loading...</div>

        <order-maps v-else  :fromLocation="fromLocation" :toLocation="toLocation" :waypoints_ob="waypoints_ob"/>
      </b-col>
    </b-row>
  

  <!-- <b-card>
    <tab  :machineData="machineData" :orderData="orderData"/>
  </b-card> -->

</b-overlay>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BOverlay
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DataService  from './data/services'
import Tab from './profile/tab'

import customerInfo from './components/customerInfo.vue'
import provider from './components/provider.vue'
import orderStatus from './components/orderStatus.vue'
import orderProducts from './components/orderProducts.vue'
import orderData from './components/orderData.vue'
import orderMaps from './components/orderMaps.vue'
export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BOverlay,
    ToastificationContent,
    Tab,
    customerInfo,
    provider,
    orderStatus,
    orderProducts,
    orderData,
    orderMaps,
  },

  data() {
    return {
      userData: {},
      machineData: [],
      provider: {},
      orderData: [],
      orderDataItemsNumber: 0,
      orderDataStatus: [],
      isLoading: true,
      orderProducts: {},
      fromLocation:{},
      toLocation:{},
      waypoints_ob:[],
      isDataLoaded: false,
      status: [
        {
          1: 'Actvie', 0: 'Inactive',
        },
        {
          1: 'light-success', 0: 'light-danger', 
        }
      ],
    }
  },
  methods: {
      getData(){
            this.isLoading = true;
            DataService.get(this.$route.params.id)
            .then(response => {
              console.log("response.data.items.length");
              console.log(response.data.fromLocation);

                  this.isDataLoaded = true; // Set flag to true once data is loaded

                  this.isLoading = false
                  this.userData    = response.data.customer
                  this.provider = response.data.provider
                  this.orderData   = response.data
                  this.orderDataItemsNumber = response.data.items.length;
                  this.orderProducts = response.data.items
                  this.fromLocation = response.data.fromLocation
                  this.toLocation = response.data.toLocation
                  this.waypoints_ob = [
                    { lat: response.data.fromLocation.latitude, lng: response.data.fromLocation.longitude },
                    { lat: response.data.toLocation.latitude, lng: response.data.toLocation.longitude }
                  ];
                  
                  this.$toast({
                        component: ToastificationContent,
                        props: {
                              title: 'successfully',
                              icon: 'BellIcon',
                              text: response.data.responseDescription,
                              variant: 'success',
                        },
                  })
            }).catch((error) => {
              console.error("Error fetching data:", error);
              this.isDataLoaded = false; // Handle error
            });
      },
      getOrderLogs() {
        this.isLoading = true;
        DataService.getOrderLogs(this.$route.params.id)
            .then(response => {
                this.orderDataStatus = response.data
            })
      },
      changeStatus(id){
            this.isLoading = true;
            DataService.changeStatus(id)
            .then(response => {
            this.isLoading = false
            if(this.userData.status == 1){
                  this.userData.status = 0
            }else{
                  this.userData.status = 1
            }
            this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'successfully',
                  icon: 'BellIcon',
                  text: response.data.responseDescription,
                  variant: 'success',
                  },
            })
            
            
            })
      },
      
  },
  mounted() {
    this.getData()
    this.getOrderLogs()
  },
  
}
</script>

<style>

</style>
