<template>
    <b-card title="مزود الخدمة">

        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">اﻹسم</span>
            </th>
            <td class="pb-50">
              {{ userData.name }}
            </td>
          </tr>

          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
                  <b-badge  :variant="status[1][userData.status]" style="cursor: pointer;">
                        {{ status[0][userData.status] }}
                  </b-badge>
            </td>
          </tr> -->

          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الوصف</span>
            </th>
            <td>
              {{ userData.description }}
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">التقييم</span>
            </th>
            <td>
              {{ userData.rating }}
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">المشاريع المكتملة</span>
            </th>
            <td>
              {{ userData.completedProjects }}
            </td>
          </tr>
          
        </table>
    </b-card>
</template>

<script>
import { BCard, BCardText, BBadge } from 'bootstrap-vue'
export default {
    components: {
        BCard,
        BCardText,
        BBadge
    },
    props : {
        userData : {
            type : Object,
            requied: true
        }
    },
    data() {
        return {
            status: [
                {
                1: 'Actvie', 0: 'Inactive',
                },
                {
                1: 'light-success', 0: 'light-danger', 
                }
            ],
        }
    },

}
</script>