<template>
    <b-card title="تفاصيل الطلب">
        <table class="mt-2 mt-xl-0 w-100">

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">التاريخ</span>
            </th>
            <td class="pb-50">
               {{orderData.preferredDate}} 
            </td>

            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الزمن</span>
            </th>
            <td class="pb-50">
               {{orderData.preferredTime}}
            </td>


            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الحالة</span>
            </th>

            <td class="pb-50 text-capitalize">
                  <b-badge  style="cursor: pointer;">
                        {{ orderData.statusText }}
                  </b-badge>
            </td>

            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">عدد المنتجات</span>
            </th>
            <td class="pb-50 text-capitalize">
                  {{ orderDataItemsNumber }}
            </td>

            

          </tr>


          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">رسوم الخدمة</span>
            </th>
            <td class="pb-50">
               {{orderData.serviceFee}} 
            </td>

            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">عمولة</span>
            </th>
            <td class="pb-50">
               {{orderData.commission}}
            </td>


            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">ضريبة القيمة المضافة</span>
            </th>

            <td class="pb-50 text-capitalize">
                        {{ orderData.vat }}
            </td>

            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">اﻹجمالي</span>
            </th>
            <td class="pb-50 text-capitalize">
                  {{ orderData.total }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الطابق</span>
            </th>
            <td class="pb-50">
               {{orderData.floor}} 
            </td>

            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">الملاحظة</span>
            </th>
            <td class="pb-50">
               {{orderData.note}}
            </td>


            

            
          </tr>

        </table>
    </b-card>
</template>


<script>
import { BCard, BBadge } from 'bootstrap-vue'

export default {
    components : {
        BCard,
        BBadge
    },
    props : {
      orderData: {
        type: [Object, Array],
        required: true,
      },
      orderDataItemsNumber : {
        type: Number,
        required: true,
      },
    },
    data() {
        return {
            // status: [{
            //   completed: 'completed', 2: 'Professional', canceled: 'canceled', pending: 'pending', 5: 'Applied',
            // },
            // {
            //   completed: 'light-primary', 2: 'light-success', canceled: 'light-danger', pending: 'light-warning', 5: 'light-info',
            // }],
        }
    },
}
</script>